@use '../../assets/styles/colors' as *;

.icon {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;

	& > svg {
		width: 100%;
		height: 100%;
	}

	// &.fill {
	// 	&.white {
	// 		& > svg {
	// 			path {
	// 				fill: $color-white;
	// 			}
	// 		}
	// 	}

	// 	&.red {
	// 		& > svg {
	// 			path {
	// 				fill: $color-red;
	// 			}
	// 		}
	// 	}

	// 	&.green {
	// 		& > svg {
	// 			path {
	// 				fill: $color-green;
	// 			}
	// 		}
	// 	}

	// 	&.blue {
	// 		& > svg {
	// 			path {
	// 				fill: $color-light-blue;
	// 			}
	// 		}
	// 	}

	// 	&.light-blue {
	// 		& > svg {
	// 			path {
	// 				fill: $color-light-blue;
	// 			}
	// 		}
	// 	}

	// 	&.violet {
	// 		& > svg {
	// 			path {
	// 				fill: $color-violet;
	// 			}
	// 		}
	// 	}

	// 	&.orange {
	// 		& > svg {
	// 			path {
	// 				fill: $color-orange;
	// 			}
	// 		}
	// 	}
	// }

	// &.stroke {
	// 	&.white {
	// 		& > svg {
	// 			path {
	// 				stroke: $color-white;
	// 			}
	// 		}
	// 	}

	// 	&.red {
	// 		& > svg {
	// 			path {
	// 				stroke: $color-red;
	// 			}
	// 		}
	// 	}

	// 	&.green {
	// 		& > svg {
	// 			path {
	// 				stroke: $color-green;
	// 			}
	// 		}
	// 	}

	// 	&.blue {
	// 		& > svg {
	// 			path {
	// 				stroke: $color-blue-focus;
	// 			}
	// 		}
	// 	}

	// 	&.light-blue {
	// 		& > svg {
	// 			path {
	// 				stroke: $color-light-blue;
	// 			}
	// 		}
	// 	}

	// 	&.violet {
	// 		& > svg {
	// 			path {
	// 				stroke: $color-violet;
	// 			}
	// 		}
	// 	}

	// 	&.orange {
	// 		& > svg {
	// 			path {
	// 				stroke: $color-orange;
	// 			}
	// 		}
	// 	}
	// }
}
