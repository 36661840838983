@use '../../../assets/styles/colors' as *;
@use '../../../assets/styles/variables' as *;

.global-not {
	padding: 12px 16px;
	background: $color-app-main;
	border-radius: 8px;
	box-shadow: 0 -8px 10px rgba(67, 72, 114, 0.0456112), 0 -2px 4px rgba(67, 72, 114, 0.07),
		0 30px 24px rgba(67, 72, 114, 0.035), 0 12px 10px rgba(67, 72, 114, 0.0456112),
		0 4px 4px rgba(67, 72, 114, 0.07);
	opacity: 0;
	transition: $transition-main;

	&.error {
		color: #fff;
		background: $color-input-error;
	}

	&.success {
		color: #fff;
		background: $color-green;
	}

	&.info {
		color: #fff;
		background: $color-blue-focus;
	}

	&.visible {
		opacity: 1;
	}
}
