@use '../../../assets/styles/colors' as *;
@use '../../../assets/styles/mixins' as *;

.avatar-file-reader,
.avatar-file-reader__label {
	position: absolute;
	top: 0;
	left: 0;
	width: 96px;
	height: 96px;
	border-radius: 50%;
}

.avatar-file-reader {
	display: flex;
	align-items: center;
	justify-content: center;
	background: $color-grey-light;
	box-shadow: 0 2px 8px rgba(56, 70, 83, 0.2);

	& > input {
		display: none;
	}

	&__label {
		cursor: pointer;
	}

	&.small {
		top: auto;
		right: 0;
		bottom: 0;
		left: auto;

		& > .icon {
			width: 14px;
			height: 14px;
		}
	}

	@include max-width(768px) {
		&.image {
			background: transparent;

			.icon {
				display: none;
			}
		}
	}
}

.avatar-file-reader.small,
.avatar-file-reader.small > .avatar-file-reader__label {
	width: 26px;
	height: 26px;
}
