@use '../../../assets/styles/colors' as *;
@use '../../../assets/styles/mixins' as *;

.mobile-menu-button {
	display: none;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	cursor: pointer;

	&:focus {
		outline: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	@include max-width(768px) {
		display: flex;
	}

	& > i {
		position: relative;
		display: block;
		width: 16px;
		height: 2px;
		background-color: $color-text-black;
		transition: 0.3s all;

		&::before,
		&::after {
			position: absolute;
			left: 0;
			height: 2px;
			background-color: $color-text-black;
			transition: 0.3s all;
			content: '';
		}

		&::before {
			top: -5px;
			width: 16px;
		}

		&::after {
			top: 5px;
			width: 10px;
		}
	}
}
