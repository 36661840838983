@use '../../../assets/styles/colors' as *;
@use '../../../assets/styles/mixins' as *;

.status {
	display: flex;
	align-items: center;

	& > span {
		color: $color-text-black;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0;
		opacity: 0.7;
	}

	& > i {
		display: block;
		width: 16px;
		height: 16px;
		margin-left: 3px;
		cursor: pointer;

		@include bg-image('svg/info.svg', auto);
	}
}
