@use '../../../../../../../assets/styles/_colors.scss' as *;
@use '../../../../../../../assets/styles/_mixins.scss' as *;

.profit-second {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	@include bg-image('/landing/X_about.png', right 50%, contain);

	&__outer-circle {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 610px;
		width: 610px;
		border-radius: 50%;
		border: 5px solid $color-landing-border;

		&::after {
			content: '';
			position: absolute;
			top: -2px;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 24px;
			height: 24px;
			background-color: $color-landing-border;
			border-radius: 50%;
		}

		&::before {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 50%;
			transform: translate(-50%, 50%);
			width: 24px;
			height: 24px;
			background-color: $color-landing-border;
			border-radius: 50%;
		}
	}

	&__inner-circle {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		height: 430px;
		width: 430px;
		border-radius: 50%;
		background-color: $color-landing-white;
		border: 3px solid $color-landing-gray-text;

		&::after {
			content: '';
			position: absolute;
			height: 430px;
			width: 430px;
			border: 50px solid $color-landing-border;
			border-radius: 50%;
		}

		&-title {
			margin-bottom: 15px;
			color: $color-landing-main-text;
			font-weight: bold;
			font-size: 24px;
		}

		&-text {
			max-width: 85%;
			color: $color-landing-main-text;
		}
	}

	.adaptive {
		display: none;
	}

	@media (max-width: 1200px) {
		padding-top: 40px;
		&__outer-circle {
			height: 540px;
			width: 540px;
		}

		&__inner-circle {
			height: 385px;
			width: 385px;

			&::after {
				width: 463px;
				height: 463px;
				border: 40px solid $color-landing-border;
			}

			&-text.text {
				font-size: 15px;
			}
		}
	}

	@media (max-width: 1000px) {
		&__outer-circle {
			transform: scale(0.88) translateX(3%);
		}
	}

	@media (max-width: 865px) {
		&__outer-circle {
			transform: scale(0.8) translateX(4%);
		}
	}

	@media (max-width: 776px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		&__outer-circle {
			transform: scale(0.6) translateX(2%);
			margin-bottom: -50px;
		}

		&__inner-circle-text {
			display: none;

			&.adaptive {
				display: block;
				text-align: center;
				color: $color-landing-white;
			}
		}
	}

	@media (max-width: 565px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 55px 15px;

		&__outer-circle {
			display: none;
		}

		.profit-second__inner-circle-title.adaptive {
			display: block;
			color: $color-landing-white;
		}

		&__inner-circle-text {
			display: none;

			&.adaptive {
				text-align: start;
			}
		}

		.icons-wrapper.adaptive {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 35px;
			margin-top: 40px;
		}
	}

	@media (max-width: 480px) {
		.icons-wrapper.adaptive {
			column-gap: 20px;
		}
	}

	@media (max-width: 380px) {
		.icons-wrapper.adaptive {
			column-gap: 10px;
		}
	}
}
