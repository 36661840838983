@use '../../../../../../../assets/styles/_colors.scss' as *;
@use '../../../../../../../assets/styles/_variables.scss' as *;

.nav-link-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 40px;
	margin-top: 10px;
	margin-left: 30px;
}

.nav-link {
	font-weight: 600;
	font-size: 12px;
	color: $color-landing-black-text;
	transition: $transition;
	white-space: nowrap;

	&:hover {
		color: $color-landing-blue;
		transform: translateY(1px);
	}

	&__active-marker {
		margin-top: 10px;
		height: 2px;
		width: 0;
		opacity: 0;
		transition: $transition;
		border-radius: 8px;
		background: $color-landing-blue;
	}

	&.active {
		position: relative;
		color: $color-landing-blue;
		pointer-events: none;

		& + .nav-link__active-marker {
			width: 40px;
			height: 2px;
			opacity: 0.8;
		}
	}
}

.nav-links.white {
	.nav-link {
		color: $color-landing-white;

		&:hover {
			text-shadow: 0px 0px 1px $color-landing-white;
		}

		&__active-marker {
			background: $color-landing-white;
		}
	}
}

@media (max-width: 1150px) {
	.nav-link-wrapper {
		margin-left: 13px;
	}
}

@media (max-width: 1000px) {
	.nav-link-wrapper {
		margin-left: 10px;
	}
}

@media (max-width: 992px) {
	.nav-link {
		font-weight: 800;
		font-size: 20px;
		line-height: 150%;
	}
}
