@mixin shift-out($shift) {
	animation: fade 0.25s ease-out backwards;

	@keyframes fade {
		from {
			transform: translateX($shift);
			opacity: 0;
		}

		to {
			transform: translateX(0);
			opacity: 1;
		}
	}
}

@mixin spin {
	animation: spin 1.25s linear infinite;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}
}
