@use '../../../../../../../assets/styles/_colors.scss' as *;

.contacts-section {
	&__footer {
		height: 120px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding-bottom: 15px;
		font-size: 12px;

		&-logo {
			margin-bottom: 10px;

			&-text {
				color: $color-landing-main-text;
			}
		}

		&-copyrigth {
			display: flex;
			align-items: flex-end;
			& > p {
				color: $color-landing-dark-gray-text;
			}
		}

		&-logo-block {
			max-width: 315px;
		}

		&-legal-address {
			max-width: 285px;

			& > p {
				color: $color-landing-main-text;
			}
		}

		.adaptive {
			display: none;
		}
	}

	@media (max-width: 992px) {
		&__footer {
			&-copyrigth {
				margin-right: 15px;
			}
		}
	}

	@media (max-width: 768px) {
		&__footer {
			&-copyrigth {
				display: none;

				&.adaptive {
					display: block;
					margin-top: 5px;
				}
			}

			&-logo-block {
				max-width: 335px;
			}
		}
	}

	@media (max-width: 576px) {
		&__footer {
			&-legal-address {
				max-width: 240px;
				margin-left: 15px;
			}

			&-logo-text {
				max-width: 240px;
			}
			&-copyrigth {
				&.adaptive {
					position: absolute;
					bottom: 5px;
					left: 50%;
					transform: translateX(-50%);
					white-space: nowrap;
				}
			}
		}
	}

	@media (max-width: 500px) {
		&__footer {
			flex-direction: column;
			height: 300px;

			&-legal-address {
				margin: 0;
			}

			.adaptive {
				display: none;
			}

			&-copyrigth {
				display: block;
				order: 3;
			}
		}
	}
}
