@use '../../../../../../../assets/styles/_mixins.scss' as *;
@use '../../../../../../../assets/styles/_colors.scss' as *;

.profit-fourth {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 100px;
	@include bg-image('/landing/bg_main.png');

	&__title {
		text-align: center;
		margin-bottom: 40px;
		color: $color-landing-main-text;
	}

	&__flip-cards {
		display: flex;

		&-title {
			margin-bottom: 15px;
			color: $color-landing-main-text;
		}

		&-text {
			color: $color-landing-main-text;
		}
	}

	@media (max-width: 768px) {
		padding: 90px 30px 130px 30px;
	}

	@media (max-width: 567px) {
		padding: 30px 15px;

		&__flip-cards {
			display: flex;
			flex-direction: column;
		}
	}
}
