@use '../../../../../../../../assets/styles/_colors.scss' as *;
@use '../../../../../../../../assets/styles/_mixins.scss' as *;
@use '../../../../../../../../assets/styles/_variables.scss' as *;

.transform-card__wrapper {
	margin: auto;
	perspective: 900px;
	padding: 0 35px;

	&.flipped .transform-card {
		transform: rotateY(180deg);
		-webkit-transform: -webkit-rotateY(180deg);

		&-shadow {
			transform: rotateY(180deg);
			-webkit-transform: -webkit-rotateY(180deg);

			transform: rotate(4deg);
			-webkit-transform: -webkit-rotate(4deg);

			box-shadow: $big-shadow;
		}

		&__face {
			&:hover {
				animation: none;
			}
		}
	}

	.transform-card {
		position: relative;
		width: 540px;
		height: 285px;
		transition: transform 0.3s;
		-webkit-transition: transform 0.3s;

		transform-style: preserve-3d;
		-webkit-transform-style: preserve-3d;

		cursor: pointer;

		&-shadow {
			position: absolute;
			z-index: 0;
			transform: translateZ(-1000px);
			-webkit-transform: -webkit-translateZ(-1000px);
			top: 1px;
			left: 36px;
			width: 538px;
			height: 283px;
			background-color: $color-landing-blue-2;
			transition: transform 0.3s;
			-webkit-transition: transform 0.3s;
			border-radius: 24px;
		}

		&__face {
			position: absolute;
			display: flex;
			justify-content: center;
			height: 100%;
			width: 100%;
			padding: 25px 15px;
			perspective: 1000;
			backface-visibility: hidden;
			-webkit-backface-visibility: hidden;
			-webkit-perspective: 1000;
			border-radius: 24px;
			box-shadow: $big-shadow;

			&:hover {
				animation: 0.15s tremor ease-out 2;
				animation-delay: 0.1s;
			}

			&-img-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				min-width: 168px;
				height: 137px;
				margin: 10px 15px 0 0;
				@include bg-image('/landing/x-bg.png', center, contain);
			}

			&--front {
				background: $color-landing-white;
				align-items: center;
			}

			&--back {
				background: $color-landing-white;
				transform: rotateY(180deg);
				-webkit-transform: -webkit-rotateY(180deg);

				.transform-card__face-img {
					width: 50%;
					transform: translateY(10%);
					-webkit-transform: -webkit-translateY(10%);
				}
			}
		}
	}

	@media (max-width: 1300px) {
		.transform-card {
			width: 490px;
			height: 265px;

			&-shadow {
				width: 488px;
				height: 263px;
			}
		}
	}

	@media (max-width: 992px) {
		padding: 0 10px;

		.transform-card {
			width: 455px;
			height: 265px;

			.profit-fourth__flip-cards-text {
				font-size: 14px;
			}

			&-shadow {
				width: 453px;
				height: 263px;
				left: 10px;
			}
		}
	}

	@media (max-width: 768px) {
		.transform-card {
			width: 265px;
			height: 455px;

			.profit-fourth__flip-cards-text {
				font-size: 14px;
			}

			&-shadow {
				width: 263px;
				height: 453px;
				left: 10px;
			}

			&__face {
				&-img-wrapper {
					margin: 0 0 25px 0;
				}
				&--back {
					flex-direction: column;
					.transform-card__face-text-wrapper {
						text-align: center;
					}
				}
			}
		}
	}

	@media (max-width: 566px) {
		.transform-card {
			width: 290px;
			height: 370px;
			margin-bottom: 30px;

			&-shadow {
				width: 288px;
				height: 368px;
			}
		}
	}
}

@keyframes tremor {
	0%,
	25% {
		transform: rotate(-1deg);
		-webkit-transform: -webkit-rotate(-1deg);
	}
	50%,
	100% {
		transform: rotate(1deg);
		-webkit-transform: -webkit-rotate(1deg);
	}
}
