@use '../../../../assets/styles/mixins' as *;
@use '../../../../assets/styles/colors' as *;

.nav {
	overflow: hidden;

	&-item + &-item {
		margin-top: 5px;
	}

	@include max-width(1400px) {
		&-item + &-item {
			margin-top: 0;
		}
	}

	@include max-width(900px) {
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 0;
		}
	}

	@include max-width(768px) {
		padding: 10px;

		&-item {
			margin-bottom: 4px;
			border-radius: 8px;

			span {
				display: inline-block;
			}

			&.nav-item--active {
				text-align: center;
				background-color: $color-app-main;
				border-right: 0;
			}
		}
	}
}
