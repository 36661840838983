@use '../../../assets/styles/colors' as *;
@use '../../../assets/styles/mixins' as *;

.edit-questionnaire-link {
	display: flex;
	align-items: center;
	color: $color-blue-focus;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.04em;

	.icon {
		margin-right: 8px;
	}

	@include max-width(1400px) {
		.icon {
			margin-right: 0;
		}

		& > span {
			display: none;
		}
	}

	@include max-width(1400px) {
		.icon {
			margin-right: 8px;
		}

		& > span {
			display: inline-block;
			color: $color-blue-focus;
			font-weight: 600;
		}
	}
}
