@use '../../../assets/styles/colors' as *;
@use '../../../assets/styles/variables' as *;
@use '../../../assets/styles/mixins' as *;

.certif-container {
	position: relative;
	width: 40px;
	height: 40px;

	.certif-button {
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		background-color: $color-blue-ghost;
		border-radius: 50%;
		cursor: pointer;

		.badge {
			position: absolute;
			top: -2px;
			right: -2px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 17px;
			height: 17px;
			background-color: $color-red;
			border: 2px solid $color-app-main;
			border-radius: 50%;

			span {
				color: $color-app-main;
				font-weight: 600;
				font-size: 10px;
			}
		}
	}

	.drop-card {
		position: absolute;
		top: 32px;
		right: 0;
		z-index: -1;
		width: 360px;
		overflow-x: hidden;
		background-color: $color-app-main;
		border-radius: 8px;
		box-shadow: $card-shadow;
		opacity: 0;
		transition: all 0.2s ease-out;

		.scroll-wrapper {
			position: relative;
			width: 100%;
			max-height: 560px;
			overflow-x: hidden;
			overflow-y: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				width: 0;
			}
		}

		&-header {
			padding: 12px 12px 0 12px;
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: 0.04em;
		}

		&-plug {
			padding-top: 20px;
			color: $color-text-black;
			line-height: 24px;
			text-align: center;
		}

		.list-container {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			padding: 12px;

			.list-item {
				position: relative;
				display: flex;
				align-items: center;
				min-height: 58px;
				padding: 4px;
				background-color: $color-grey-light;
				border-radius: 4px;
				cursor: pointer;

				img {
					width: 64px;
					height: 64px;
					margin-right: 4px;
					object-fit: cover;
					border-radius: 4px;
				}

				.desc {
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin-left: 16px;
				}

				.text {
					font-weight: 500;
					font-size: 14px;
					line-height: 20px;
				}

				.code-wrapper {
					font-size: 14px;
					line-height: 20px;

					.label {
						margin-right: 9px;
						opacity: 0.7;
					}

					.code {
						letter-spacing: 0.2em;
					}
				}
			}

			.list-item--link + .list-item--link {
				margin-top: 8px;
			}
		}
	}

	&.hover .drop-card {
		top: 48px;
		z-index: 101;
		opacity: 1;
	}

	@include max-width(768px) {
		width: 25px;
		height: 25px;

		& > .certif-button {
			background-color: transparent;
		}
	}

	@include max-width(480px) {
		position: initial;

		.drop-card {
			width: 100%;
		}
	}
}
