@use '../../assets/styles/colors' as *;

.privacy-policy {
	&__content {
		padding-right: 25px;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
			background-color: $color-app-main;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #d1d1d1;
			border-radius: 2px;
		}
	}

	.modal-wrapper {
		.modal-container {
			width: 80%;
			min-width: 280px;
			max-width: 640px;
			height: 80%;
			padding: 40px 15px 20px 40px;
		}
	}

	&__links {
		.active-text {
			margin-right: 50px;
		}
	}
}
