@use '../../../assets/styles/colors' as *;
@use '../../../assets/styles/mixins' as *;
@use '../../../assets/styles/variables' as *;

.header-status {
	display: flex;
	align-items: center;
	padding: 8px 16px;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.04em;
	border-radius: 50px;

	&__icon {
		width: 24px;
		height: 24px;
		margin-right: 8px;
	}

	.drop-card {
		position: absolute;
		top: 32px;
		right: 0;
		left: 50%;
		z-index: -1;
		display: block;
		width: 100%;
		max-width: 560px;
		padding: 15px 0;
		overflow-x: hidden;
		text-align: center;
		background-color: $color-app-main;
		border-radius: 8px;
		box-shadow: $card-shadow;
		transform: translateX(-50%);
		opacity: 0;
		transition: all 0.2s ease-out;
	}

	&.fail {
		background-color: $color-red-ghost;

		svg {
			path {
				fill: $color-red;
			}
		}

		& > span {
			color: $color-red;
		}
	}

	&.waiting {
		background-color: $color-blue-ghost;

		svg {
			path {
				fill: $color-blue-focus;
			}
		}

		& > span {
			color: $color-blue-focus;
		}
	}

	@include max-width(768px) {
		padding: 8px;

		&.hover > .drop-card {
			top: 48px;
			right: 0;
			z-index: 20;
			opacity: 1;
		}

		&__icon {
			margin-right: 0;
		}

		& > span {
			display: none;
		}
	}

	@include max-width(480px) {
		position: initial;
		&.hover > .drop-card {
			width: 100%;
		}
	}
}
