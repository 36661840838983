@use '../../../../../../../assets/styles/_colors.scss' as *;
@use '../../../../../../../assets/styles/_variables.scss' as *;

.landing-faq__item {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 8px;
	max-width: 530px;
	background: $color-landing-ligth-gray;
	border-radius: 24px;
	transition: $transition;
	height: fit-content;
	cursor: pointer;
	border: none;

	&.show {
		&:hover {
			background: $color-landing-ligth-gray;
			box-shadow: $shadow;

			.landing-faq__item-button {
				background-color: $color-landing-white;
				box-shadow: $shadow;
			}
		}
	}

	&:hover {
		background-color: $color-landing-white;
		box-shadow: $shadow;

		.landing-faq__item-button {
			background: $color-landing-ligth-gray;
			box-shadow: none;
		}

		.landing-faq__item-question {
			color: $color-landing-blue;
		}
	}

	&-button {
		position: relative;
		margin-right: 30px;
		min-width: 64px;
		height: 64px;
		box-shadow: 3.64394px 5.01614px 16.9px rgba(0, 0, 0, 0.055),
			0.797112px 1.09728px 6.0125px rgba(0, 0, 0, 0.03575);
		border-radius: 24px;
		background-color: $color-landing-white;
		transition: $transition;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			background-color: $color-landing-blue;
			height: 3px;
			width: 12px;
			transition: $transition;
		}

		&::before {
			left: 48%;
			transform: rotate(-45deg);
		}

		&::after {
			right: 44%;
			transform: rotate(45deg);
		}

		&.show {
			&::before {
				left: 35%;
			}

			&::after {
				right: 35%;
			}
		}
	}

	&-question-wrapper {
		padding-top: 15px;
		transition: $transition;
	}

	&-question {
		text-align: start;
		font-weight: bold;
		font-size: 18px;
		color: $color-landing-main-text;
	}

	&-answer {
		height: 0;
		overflow: hidden;
		opacity: 0;
		text-align: left;
		margin-top: 10px;
		transition: $transition;
		color: $color-landing-main-text;
		font-size: 16px;
		line-height: 150%;

		&.show {
			height: 145px;
			opacity: 1;
		}
	}

	@media (max-width: 992px) {
		max-width: 460px;
	}

	@media (max-width: 768px) {
		max-width: 100%;

		&-answer {
			&.show {
				height: 245px;
			}
		}
	}
}
