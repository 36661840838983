@use '../../assets/styles/colors' as *;
@use '../../assets/styles/variables' as *;

.tooltip {
	position: fixed;
	right: 10px;
	left: 10px;
	z-index: 20;
	display: flex;
	width: fit-content;
	max-width: 250px;
	padding: 16px;
	color: $color-text-black;
	font-size: 12px;
	font-style: normal;
	line-height: 20px;
	letter-spacing: 0.4px;
	text-align: center;
	background: $color-app-main;
	border-radius: 8px;
	box-shadow: $card-shadow;
	visibility: hidden;
	opacity: 0;
	transition: none;
	transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;

	&.show {
		visibility: visible;
		opacity: 1;
	}

	&__angle {
		position: absolute;
		top: -18px;
		color: $color-app-main;
		font-size: 20px;
		text-shadow: 0 -8px 8px rgba(46, 49, 60, 0.1);
		transform: scaleX(2);
	}

	&__reverse-angle {
		top: auto;
		bottom: -13px;
		display: none;
		text-shadow: 0 8px 8px rgba(46, 49, 60, 0.1);
	}
}
