@use '../../../../../assets/styles/_colors.scss' as *;
@use '../../../../../assets/styles/_variables.scss' as *;

.header {
	position: sticky;
	top: 0;
	display: flex;
	z-index: 2;
	padding: 20px 30px;
	justify-content: space-between;

	.header-logo-wrapper {
		z-index: 1;
	}

	&-body {
		display: flex;
		align-items: center;
	}

	&-buttons {
		display: flex;
		align-items: center;
		margin-left: 70px;

		&__join-button {
			margin-left: 20px;
		}
	}

	&.white {
		.header-buttons__sign-button {
			border-color: $color-landing-white;

			& > span {
				color: $color-landing-white;
				transition: $transition;
			}

			&:hover {
				box-shadow: $button-shadow-white-inset;
				opacity: 0.9;
			}
		}

		.header-buttons__join-button {
			color: $color-landing-blue;
		}
	}

	.header-buttons__burger {
		display: none;
	}

	@media (max-width: 1150px) {
		&-buttons {
			margin-left: 20px;
		}
	}

	@media (max-width: 992px) {
		.header-buttons__burger {
			z-index: 1;
			position: relative;
			display: block;
			border: none;
			width: 40px;
			height: 25px;
			margin-left: 40px;

			& > span {
				position: absolute;
				left: 0;
				top: 11px;
				width: 100%;
				height: 4px;
				border-radius: 8px;
				opacity: 1;
				background-color: $color-landing-blue;
				transition: $transition;
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				left: 0;
				width: 100%;
				height: 4px;
				border-radius: 8px;
				background-color: $color-landing-blue;
				transition: $transition;
			}

			&::after {
				top: 0;
			}

			&::before {
				bottom: 0;
			}

			&.open {
				& > span {
					opacity: 0;
				}

				&::before {
					top: 5px;
					transform: rotate(45deg);
				}

				&::after {
					top: 5px;
					transform: rotate(-45deg);
				}
			}
		}
	}

	@media (max-width: 576px) {
		.header-buttons {
			display: none;
		}
	}
}
