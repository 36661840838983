@use '../../assets/styles/colors' as *;
@use '../../assets/styles/variables' as *;

.active-text {
	color: $color-blue;
	cursor: pointer;
	transition: color 0.15s ease-in-out;

	&:hover {
		color: $color-blue-focus;
	}
}
