@use '../../../../../../assets/styles/colors' as *;
@use '../../../../../../assets/styles/mixins' as *;

.form-header {
	margin-bottom: 32px;

	h1 {
		font-weight: 500;
		font-size: 28px;
		line-height: 32px;
		letter-spacing: 0.04em;
	}

	p {
		margin-top: 16px;
		color: $color-text-black;
		font-size: 16px;
		line-height: 24px;
		opacity: 0.7;
	}

	@include max-width(480px) {
		margin-bottom: 16px;

		h1 {
			font-size: 20px;
			line-height: 26px;
			letter-spacing: 0.04em;
		}
	}
}
