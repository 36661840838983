@use '../../../assets/styles/colors' as *;
@use '../../../assets/styles/mixins' as *;
@use '../../../assets/styles/variables' as *;

.light-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 12px 18px;
	color: $color-blue-focus;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.04em;
	background-color: $color-blue-ghost;
	border: none;
	border-radius: 8px;
	outline: none;
	cursor: pointer;

	&.link:hover {
		color: $color-blue-focus;
	}

	&.red {
		color: $color-red;
		background-color: $color-red-ghost;
	}

	&.transparent {
		background-color: transparent;
	}

	i {
		display: block;
		width: 24px;
		height: 24px;
		margin-right: 5px;
		background-position: center;
		background-size: cover;
	}

	@include max-width(768px) {
		padding: 6px 9px;
	}
}
