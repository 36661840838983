@use '../../../../../../../assets/styles/colors' as *;
@use '../../../../../../../assets/styles/mixins' as *;

.integration-modal-content {
	&__code {
		margin-bottom: 15px;
		font-weight: 500;
		font-size: 38px;
		line-height: 32px;
		letter-spacing: 0.4em;
	}

	&__copy-code {
		display: flex;
		align-items: center;
		margin-bottom: 52px;
		color: $color-blue-focus;
		font-size: 14px;
		line-height: 20px;
		cursor: pointer;

		& > .copy-icon {
			width: 16px;
			height: 16px;
			margin-right: 5px;

			svg {
				path {
					stroke: $color-blue-focus;
				}
			}
		}
	}

	&__success {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
		color: $color-green;
		font-weight: 500;
		font-size: 16px;
	}

	@include max-width(768px) {
		&__code {
			margin-bottom: 15px;
			font-weight: 500;
			font-size: 28px;
			line-height: 22px;
			letter-spacing: 0.4em;
		}
	}
}
