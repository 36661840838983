@use '../../../../../../assets/styles/_colors.scss' as *;
@use '../../../../../../assets/styles/_mixins.scss' as *;

.landing-faq {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 100px;

	&__title {
		margin-bottom: 30px;
		color: $color-landing-main-text;
	}

	&__faq {
		display: flex;
		flex-wrap: wrap;
		max-width: 1090px;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 30px;
	}

	&__aniqestions {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		max-width: 1090px;
		background: $color-landing-bg-gradient;
		border-radius: 24px;

		&-text-wrapper {
			padding: 22px 55px;
			color: $color-landing-white;
			@include bg-image('/landing/white-x.png', left, 50%);
		}

		&-text-title {
			color: $color-landing-white;
			font-weight: 800;
			font-size: 27px;
			text-transform: uppercase;
		}

		&-text {
			font-weight: bold;
			font-size: 18px;
			color: $color-landing-white;
		}

		& .button {
			position: relative;
			border-color: $color-landing-white;
			background-color: $color-landing-white;
			margin-right: 55px;
			color: $color-landing-purple;
			padding-right: 55px;

			&:hover {
				&::before {
					right: 25px;
				}

				&::after {
					right: 25px;
				}
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				right: 30px;
				background-color: $color-landing-purple;
				height: 2px;
				width: 8px;
			}

			&::before {
				top: 44%;
				transform: rotate(45deg);
			}

			&::after {
				top: 56%;
				transform: rotate(-45deg);
			}
		}
	}

	@media (max-width: 1400px) {
		padding: 30px;
	}

	@media (max-width: 768px) {
		&__aniqestions {
			.button {
				margin-right: 30px;
			}

			&-text-wrapper {
				padding: 22px 10px 22px 30px;
			}

			&-text-title {
				font-size: 24px;
			}

			&-text {
				font-size: 14px;
			}
		}
	}

	@media (max-width: 576px) {
		&__aniqestions {
			flex-direction: column;
			padding-bottom: 25px;

			&-text-wrapper {
				padding: 22px 55px;
				color: $color-landing-white;
				background-image: none;
			}
		}
	}
}
