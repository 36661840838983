@use '../../assets/styles/colors' as *;
@use '../../assets/styles/mixins' as *;

.points-counter {
	display: flex;
	align-items: center;
	font-weight: inherit;

	&__count {
		font-weight: inherit;
		letter-spacing: inherit;
	}

	&__icon {
		width: 20px;
		height: 20px;
		margin-left: 6px;
	}

	&.increase {
		.points-counter__count {
			color: $color-green;
		}
	}

	&.decrease {
		.points-counter__count {
			color: $color-red;
		}
	}

	&.increase > .points-counter__icon,
	&.decrease > .points-counter__icon,
	&.white > .points-counter__icon {
		width: 16px;
		height: 16px;
	}

	&.white {
		align-items: flex-end;

		.points-counter__count {
			font-weight: 500;
			font-size: 28px;
			line-height: 32px;
			letter-spacing: 0.04em;
		}

		.points-counter__icon {
			margin-bottom: 8px;

			svg {
				path {
					fill: $color-app-main;
				}
			}
		}
	}
}
