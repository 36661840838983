@use '../../../assets/styles/colors' as *;
@use '../../../assets/styles/variables' as *;
@use '../../../assets/styles/mixins' as *;

.button-main {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	height: 48px;
	border: none;
	border-radius: 8px;
	outline: none;
	cursor: pointer;
	transition: $transition-main;

	.label {
		position: relative;
		z-index: 1;
		color: $color-app-main;
		font-weight: 600;
		letter-spacing: 0.04em;
		white-space: nowrap;
	}

	&.md1 {
		padding: 0 30px;

		.label {
			font-size: 16px;
		}

		@include max-width(768px) {
			padding: 0 15px;

			.label {
				font-size: 14px;
			}
		}
	}

	&.md2 {
		padding: 0 50px;

		.label {
			font-size: 16px;
		}
	}

	&.lg {
		padding: 0 72px;

		.label {
			font-size: 16px;
		}
	}

	.bg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: $color-blue;
		border-radius: 8px;
		transition: $transition-main;
	}

	.arrow {
		position: relative;
		top: 1px;
		z-index: 1;
		display: block;
		width: 24px;
		height: 24px;
		margin-left: 8px;
		transition: $transition-main;

		@include bg-image('svg/chevron-right-white.svg');
	}

	&:hover .arrow {
		transform: translateX(4px);
	}

	&.uppercase {
		& > .label {
			text-transform: uppercase;
		}
	}

	&:disabled {
		.bg {
			background-color: $color-button-disabled;
		}

		user-select: none;
		pointer-events: none;
	}

	&::after {
		position: absolute;
		top: 8px;
		left: 8px;
		width: calc(100% - 16px);
		height: calc(100% - 8px);
		box-shadow: 0 0 0 transparent;
		transition: $transition-main;
		content: '';
	}

	&:not(:disabled):hover {
		.bg {
			background-color: $color-blue-focus;
		}

		&::after {
			box-shadow: $button-blue-shadow;
		}
	}

	&:not(:disabled).white {
		.bg {
			background-color: $color-app-main;
		}

		&:hover {
			.bg {
				background-color: $color-grey-light;
			}

			&::after {
				box-shadow: $button-white-shadow;
			}
		}

		.label {
			color: $color-text-black;
		}
	}

	&:not(:disabled).red {
		.bg {
			background-color: $color-red;
		}

		&:hover {
			.bg {
				background-color: $color-red-focus;
			}

			&::after {
				box-shadow: $button-red-shadow;
			}
		}
	}

	&:not(:disabled).green {
		.bg {
			background-color: $color-green;
		}

		&:hover {
			.bg {
				background-color: $color-green-focus;
			}

			&::after {
				box-shadow: $button-green-shadow;
			}
		}
	}

	&:not(:disabled).purple {
		.bg {
			background-color: $color-purple;
		}

		&:hover {
			.bg {
				background-color: $color-purple;
			}

			&::after {
				box-shadow: $color-red-light-bg-2;
			}
		}
	}
}
