@use '../../../assets/styles/mixins' as *;
@use '../../../assets/styles/colors' as *;

.header-block {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 26px;
	background-color: $color-app-main;
	border-bottom: 1px solid $color-border-light;

	&__icon {
		display: block;
		width: 120px;
		height: 60px;
		@include bg-image('svg/mobile-menu/mobile-menu-logo.svg', center, contain);

		a {
			display: block;
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}
}
