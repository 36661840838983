@use './colors' as *;
@use './mixins' as *;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: $color-text-black;
	font-weight: 400;
	font-family: Commissioner, sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
	word-break: normal;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	outline: none;

	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		background-color: $color-app-main;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #d1d1d1;
		border-radius: 2px;
	}
}

html {
	height: 100%;

	body {
		height: 100%;
		min-width: 320px;
	}

	#root {
		height: 100%;
	}
}

.bold {
	font-weight: 600;
}

pre {
	margin: 0;
	color: #4d5063;
	font-weight: 400;
	font-family: Commissioner, sans-serif;
	white-space: break-spaces;
}

.bold {
	font-weight: 600;
}

.nowrap {
	white-space: nowrap;
}

a {
	color: $color-text-black;
	text-decoration: none;

	&:hover {
		color: $color-text-black;
	}
}

// Общие стили для компонентов фильтров
.app-filters {
	max-height: calc(100vh - 200px);
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&.in-panel {
		max-height: 100%;
		padding: 15px 20px;
	}
}

.filter-image {
	width: 100%;
	height: 40px;
	margin-bottom: 10px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-left: 2px solid transparent;
	position: relative;
	z-index: 0;

	&::before {
		content: '';
		position: absolute;
		background-color: $color-blue-focus;
		opacity: 0;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: -1;
	}

	i {
		display: block;
		width: 20px;
		height: 20px;
		@include bg-image('svg/filter.svg', center);
		background-position: center;
	}

	&.active {
		border-left: 2px solid $color-blue-focus;
		&::before {
			opacity: 0.1;
		}
	}

	@include max-width(768px) {
		.filter-image {
			display: none;
		}
	}
}

.title {
	font-weight: 900;
	font-size: 37px;
	text-transform: uppercase;
}

.subtitle {
	font-weight: 600;
	font-size: 24px;
}

.text {
	font-size: 18px;
	line-height: 150%;
}

// #chat-application {
// 	@include max-width(768px) {
// 		bottom: 50px !important;
// 	}
// }
