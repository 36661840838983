@use '../../assets/styles/colors' as *;
@use '../../assets/styles/mixins' as *;
@use '../../assets/styles/variables' as *;

.arrow-link {
	display: flex;
	align-items: center;
	gap: 10px;
	width: fit-content;
	color: $color-blue-focus;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.04em;
	transition: $transition-main;

	&__icon {
		path {
			transition: $transition-main;
		}
	}

	&:hover {
		color: $color-text-black;

		.arrow-link__icon {
			path {
				stroke: $color-text-black;
			}
		}
	}

	&.left {
		flex-direction: row-reverse;

		.arrow-link__icon {
			transform: rotate(180deg);
		}
	}

	&.white {
		.arrow-link__icon {
			path {
				stroke: $color-app-main;
			}
		}
	}
}
