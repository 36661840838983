@use '../../assets/styles/colors' as *;

.checkbox-item {
	label {
		position: relative;
		padding-left: 25px;
		cursor: pointer;

		.checkbox__text {
			transition: opacity 0.15s ease-out;
		}

		&:hover .checkbox__text {
			opacity: 0.7;
		}

		&::before {
			position: absolute;
			top: 1px;
			left: 0;
			width: 16px;
			height: 16px;
			background: #fff;
			border: 1px solid $color-border;
			border-radius: 2px;
			cursor: pointer;
			content: '';
		}
	}

	input {
		display: none;

		&:checked + label::before {
			background: $color-blue-focus;
			border: none;
		}

		&:checked + label .checkbox__tick::before,
		&:checked + label .checkbox__tick::after {
			position: absolute;
			width: 2px;
			background-color: $color-app-main;
			content: '';
			pointer-events: none;
		}

		&:checked + label .checkbox__tick::before {
			top: 4px;
			left: 9px;
			height: 10px;
			transform: rotate(40deg);
		}

		&:checked + label .checkbox__tick::after {
			top: 8px;
			left: 4px;
			width: 2px;
			height: 4px;
			transform: rotate(-45deg);
		}
	}
}
