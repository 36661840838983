@use '../../../assets/styles/mixins' as *;
@use '../../../assets/styles/colors' as *;

.body-block {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 600px;
	height: calc(100vh - 61px);
	margin: 0 auto;
	padding: 0 15px;

	&__enter-password {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;

		&__header {
			margin-bottom: 30px;
			text-align: center;
		}

		&__form {
			display: flex;
			gap: 15px;
			align-items: center;

			& > .icon {
				margin-bottom: 20px;
			}

			& > p {
				font-weight: 600;
				font-size: 20px;
			}
		}
	}

	@include max-width(600px) {
		&__enter-password {
			&__form {
				flex-direction: column;
				width: 100%;

				& > button {
					width: 100%;
				}
			}
		}
	}
}
