@use '../../assets/styles/colors' as *;
@use '../../assets/styles/mixins' as *;

.app-layout {
	height: 100%;

	& > .container {
		display: flex;
		flex: 1;
		align-items: stretch;
		height: calc(100% - 60px);

		& > .page-container {
			flex: 1 1 100%;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				width: 0;
			}
		}
	}

	.mobile-menu-bottom {
		position: fixed;
		bottom: 0;
		z-index: 10;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		height: 52px;
		background-color: #fff;
		z-index: 10;
		box-shadow: 0px -12px 10px rgba(67, 72, 114, 0.02), 0px -4px 4px rgba(67, 72, 114, 0.04);

		& > nav {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			width: 100%;
			height: 100%;
			padding: 0 10px;
			overflow-x: auto;
			mask-image: linear-gradient(
				90deg,
				transparent 0,
				rgba(0, 0, 0, 0.25) 9px,
				#000 18px,
				#000 calc(100% - 18px),
				rgba(0, 0, 0, 0.45) calc(100% - 9px),
				transparent
			);

			&::-webkit-scrollbar {
				display: none;
			}

			& > .nav-item {
				flex-direction: column;
				justify-content: center;
				width: 100%;
				margin-top: 0;
				margin-bottom: 0;
				padding: 5px 10px;
				border: 0;
				border-radius: 0;
				filter: grayscale(1);

				span {
					display: block;
					font-weight: 500;
					font-size: 12px;
					line-height: 100%;
					white-space: nowrap;
				}

				&.nav-item--active {
					background-color: transparent;
					border: 0;
					filter: grayscale(0);
				}

				& > .icon {
					margin-right: 0;
				}
			}
		}
	}
}
