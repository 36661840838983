@use '../../assets/styles/mixins' as *;

.logo {
	display: flex;
	align-items: center;

	& > i {
		display: block;
		width: 110px;
		height: 24px;

		@include bg-image('svg/x-cluster-string.svg', auto);
	}
}
