@use '../../../../../../assets/styles/_colors.scss' as *;
@use '../../../../../../assets/styles/_mixins.scss' as *;

.about-section {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	&__picture-wrapper {
		display: flex;
		padding: 40px;
	}

	&__text-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: 50%;
		padding: 40px;
		@include bg-image('/landing/X_about.png');

		.about-section__text {
			max-width: 600px;

			&-title {
				position: relative;
				margin-bottom: 15px;
				line-height: 45px;
				color: $color-landing-white;

				&::before {
					content: 'о проекте';
					position: absolute;
					bottom: 70%;
					left: 0;
					white-space: nowrap;
					text-transform: uppercase;
					color: transparent;
					font-weight: 800;
					font-size: 70px;
					opacity: 0.1;
					-webkit-text-stroke: 2px $color-landing-white;
				}
			}

			&-item {
				color: $color-landing-white;
				margin-bottom: 25px;
				.bold {
					color: $color-landing-white;
				}
			}
		}
	}

	@media (max-width: 768px) {
		&__picture-wrapper {
			padding: 40px 0;
		}

		&__text-wrapper {
			.about-section__text {
				&-title {
					&::before {
						font-size: 50px;
						bottom: 40%;
					}
				}
			}
		}
	}

	@media (max-width: 576px) {
		flex-direction: column-reverse;

		&__picture-wrapper {
			& > img {
				transform: translateX(-12%);
				width: 100%;
				object-fit: contain;
			}
		}

		&__text-wrapper {
			width: 100%;
		}
	}
}
