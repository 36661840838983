@use '../../../../../../assets/styles/_colors.scss' as *;
@use '../../../../../../assets/styles/_variables.scss' as *;

.pagination-item-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 12px;
	height: 12px;
	margin-bottom: 5px;

	&:hover .pagination-item {
		width: 12px;
		height: 12px;

		&.active {
			width: 6px;
			height: 6px;
			pointer-events: none;
		}
	}
}

.pagination-item {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: $color-landing-gray-text;
	transition: $transition;

	&.active {
		background-color: $color-landing-blue;
	}
}

.pagination.white {
	.pagination-item.active {
		background-color: $color-landing-white;
	}
}
