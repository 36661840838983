@use '../../../../../../../assets/styles/colors' as *;
@use '../../../../../../../assets/styles/mixins' as *;

.integrations-item {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__text {
		display: flex;
		align-items: center;
	}

	&__icon {
		width: 20px;
		height: 20px;
		margin-right: 12px;

		& > i {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__button {
		width: 160px;

		&--complete {
			padding: 12px 18px;
			color: $color-green;
			font-size: 16px;
			line-height: 24px;
		}
	}

	@include max-width(768px) {
		&__button {
			&--complete {
				padding: 6px 9px;
				text-align: center;
			}
		}
	}
}
