@use '../../assets/styles/colors' as *;
@use '../../assets/styles/mixins' as *;

.avatar {
	position: relative;

	&__image {
		position: relative;
		width: 80px;
		height: 80px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 50%;
		&-blur {
			display: none;
		}

		&--empty {
			background-color: $color-grey-light;
		}

		&.plug {
			background-size: 80%;

			&-0 {
				background-color: $color-avatar-coral;
			}
			&-2 {
				background-color: $color-avatar-lagoon;
			}
			&-3 {
				background-color: $color-avatar-gray;
			}
			&-4 {
				background-color: $color-avatar-purple;
			}
			&-5 {
				background-color: $color-avatar-mustard;
			}
			&-6 {
				background-color: $color-avatar-lavender;
			}
			&-7 {
				background-color: $color-avatar-mint;
			}
			&-8 {
				background-color: $color-skill-noob;
			}
			&-9 {
				background-color: $color-avatar-mint;
			}
			&-1 {
				background-color: $color-avatar-lagoon;
			}
		}
	}

	&__dropdown {
		position: absolute;
		top: 40px;
		right: 0;
		z-index: 100;
		padding: 16px;
		background-color: $color-app-main;
		border-radius: 8px;
		box-shadow: 0 -8px 10px rgba(67, 72, 114, 0.0456112), 0 -2px 4px rgba(67, 72, 114, 0.07),
			0 30px 24px rgba(67, 72, 114, 0.035), 0 12px 10px rgba(67, 72, 114, 0.0456112),
			0 4px 4px rgba(67, 72, 114, 0.07);
		visibility: hidden;
		opacity: 0;
		transition: all 0.2s ease-out;

		&.show {
			top: 44px;
			visibility: visible;
			opacity: 1;
		}

		& > .dropdown__list {
			margin: 0;
			padding: 0;
			list-style-type: none;

			.dropdown__list-item {
				display: flex;
				align-items: center;
				white-space: nowrap;
				cursor: pointer;

				div {
					display: flex;
					align-items: center;
					width: 100%;
					white-space: nowrap;
				}

				.icon {
					margin-right: 8px;
				}
			}

			.dropdown__list-item + .dropdown__list-item {
				margin-top: 10px;
			}
		}
	}

	&.large {
		.avatar__image {
			width: 96px;
			height: 96px;
			box-shadow: 0 2px 8px rgba(56, 70, 83, 0.2);
		}
	}

	&.small {
		.avatar__image {
			width: 40px;
			height: 40px;
		}
	}

	&.plug {
		background-size: 80%;

		&-0 {
			background-color: $color-avatar-coral;
		}
		&-2 {
			background-color: $color-avatar-lagoon;
		}
		&-3 {
			background-color: $color-avatar-gray;
		}
		&-4 {
			background-color: $color-avatar-purple;
		}
		&-5 {
			background-color: $color-avatar-mustard;
		}
		&-6 {
			background-color: $color-avatar-lavender;
		}
		&-7 {
			background-color: $color-avatar-mint;
		}
		&-8 {
			background-color: $color-skill-noob;
		}
		&-9 {
			background-color: $color-avatar-mint;
		}
		&-1 {
			background-color: $color-avatar-lagoon;
		}
	}
}
