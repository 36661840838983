@use '../../../../../assets/styles/_colors.scss' as *;
@use '../../../../../assets/styles/_variables.scss' as *;

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 40px;
	border-radius: 8px;
	border: 1px solid $color-landing-blue;
	background-color: transparent;
	transition: $transition;

	& > span {
		color: $color-landing-blue;
		font-weight: 600;
		font-size: 12px;
		line-height: 150%;
	}

	&::before,
	&::after {
		transition: $transition;
	}

	&:hover {
		box-shadow: $button-shadow-blue-inset;
	}

	& > i {
		display: block;
		margin-left: 13px;
		width: 17px;
		height: 17px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	&.blue {
		background-color: $color-landing-blue;

		& > span {
			color: $color-landing-white;
		}

		&:hover {
			box-shadow: $button-shadow-blue;
			background-color: $color-landing-blue-hover;
		}
	}

	&.white {
		background-color: $color-landing-white;
		border-color: $color-landing-white;

		&:hover {
			box-shadow: $button-shadow-white;
		}
	}

	&.big {
		text-transform: uppercase;
		padding: 25px 55px;
		border-radius: 24px;
	}

	@media (max-width: 1200px) {
		&.big {
			padding: 20px 35px;
			border-radius: 18px;
		}
	}

	@media (max-width: 1135px) {
		&.big {
			min-width: 180px;
			padding: 20px 25px;
			& > span {
				font-size: 10px;
			}
		}
	}

	@media (max-width: 992px) {
		&.big {
			min-width: 165px;
			padding: 13px 20px;
			border-radius: 14px;

			& > span {
				font-weight: bold;
			}
		}
	}

	@media (max-width: 965px) {
		&.big {
			min-width: 135px;
			width: 135px;
			& > i {
				display: none;
			}
		}
	}

	@media (max-width: 768px) {
		&.big {
			min-width: 165px;
			width: 165px;
			padding: 16px 10px;

			& > span {
				font-size: 8px;
			}
		}
	}

	@media (max-width: 420px) {
		&.big {
			min-width: 135px;
			width: 135px;

			& > span {
				font-size: 10px;
			}
		}
	}
}
