@use '../../../../../../assets/styles/_mixins.scss' as *;
@use '../../../../../../assets/styles/_colors.scss' as *;

.partner-section {
	display: flex;
	padding: 100px;
	height: 100%;
	@include bg-image('/landing/X_about.png', right, contain);

	&__img-wrapper {
		width: 50%;

		display: flex;
		align-items: center;

		& > img {
			width: 100%;
		}
	}

	&__info-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding-left: 20px;
		width: 50%;
	}

	&__info-title {
		margin-bottom: 25px;
		font-weight: bold;
		font-size: 24px;
		color: $color-landing-white;
	}

	&__info-text {
		font-size: 16px;
		line-height: 150%;
		margin-bottom: 30px;
		max-width: 480px;
		color: $color-landing-white;
	}

	.button {
		border-color: $color-landing-white;
		background-color: $color-landing-white;
		color: $color-landing-purple;
	}

	@media (max-width: 1400px) {
		padding: 30px;
	}

	@media (max-width: 576px) {
		flex-direction: column-reverse;
		@include bg-image('/landing/X_about.png', right, 130%);

		&__info-wrapper {
			width: 100%;
			height: fit-content;
			margin-top: 120px;
		}

		&__img-wrapper {
			width: 100%;
			flex-grow: 1;
		}
	}
}
