@use '../../assets/styles/colors' as *;
@use '../../assets/styles/mixins' as *;

.app-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	padding: 0 26px;
	background-color: $color-app-main;
	border-bottom: 1px solid $color-border-light;

	&.empty {
		height: 68px;
	}

	&.hideMenu {
		padding-left: 0;
		.mobile-menu-button {
			display: none;
		}
	}

	& > .link-logo {
		margin-left: 35px;
	}

	& > .mobile-header-logo {
		position: relative;
		display: none;
		justify-content: center;
		width: 100%;
		max-width: 100%;
		height: 60px;

		& > i {
			display: block;
			width: 120px;
			background: url('../../assets/media/svg/mobile-menu/mobile-menu-logo.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}

		& > a {
			position: absolute;
			width: 100%;
			height: 60px;
		}

		@include max-width(768px) {
			display: flex;
			align-items: center;

			& > a {
				position: static;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				opacity: 0.2;

				&.active {
					opacity: 1;
				}
			}
		}

		@include max-width(400px) {
			& > a {
				.cluster {
					width: 60px;
				}

				.work {
					width: 40px;
				}
				.campus {
					width: 50px;
				}
			}
		}
	}

	nav {
		display: flex;
		gap: 20px;
		align-items: stretch;
		height: 100%;

		@include max-width(768px) {
			display: none;
		}

		.nav-link {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: fit-content;
			padding: 0 15px;
			opacity: 0.2;

			& > .x-icon {
				position: relative;
				margin-left: 25px;
			}

			&.active {
				background-color: $color-blue-ghost;
				border-bottom: 1px solid $color-blue-focus;
				opacity: 1;

				& > .x-icon {
					&::before {
						position: absolute;
						top: -6px;
						left: -25px;
						display: block;
						width: 21px;
						height: 17px;
						content: '';

						@include bg-image('svg/x-cluster.svg');
					}
				}
			}
		}
	}

	.x-name {
		height: 7px;

		&.cluster {
			width: 64px;
			@include bg-image('svg/cluster.svg');
		}

		&.work {
			width: 43px;
			@include bg-image('svg/work.svg');
		}

		&.campus {
			width: 57px;
			@include bg-image('svg/campus.svg');
		}
	}

	.info-container {
		display: flex;
		gap: 12px;
		align-items: center;
		justify-content: space-between;

		.avatar {
			width: 40px;
			height: 40px;
			background-color: $color-grey-light;
			border-radius: 50%;
		}
	}

	@include max-width(1400px) {
		padding: 0 8px 0 60px;

		.points-counter {
			margin-right: 10px;

			&__count {
				font-weight: 500;
				font-size: 20px;
			}
		}
	}

	@include max-width(768px) {
		height: 40px;
		padding: 0 8px;

		.mobile-header-logo {
			width: 100%;
			max-width: 300px;
			height: 40px;

			a.nav-link {
				flex-direction: row;
				width: 0;
				height: 40px;
				padding: 0;
				background-color: transparent;
				border-bottom: 0;
				visibility: hidden;
				transition: 0.3s all;

				& > .x-icon {
					margin-left: 0;
				}

				&.active {
					display: flex;
					width: min-content;
					visibility: visible;

					& > .x-icon {
						margin-left: 25px;
					}
				}

				.x-name {
					height: 7px;
					background-position: center right;
				}
			}
		}

		.mobile-menu-button {
			justify-content: flex-start;
			width: 40px;
			height: 40px;

			&.active {
				i {
					background-color: transparent;
					transform: rotate(180deg);

					&::before {
						top: 0;
						transform: rotate(45deg);
					}

					&::after {
						top: 0;
						width: 16px;
						transform: rotate(-45deg);
					}
				}
			}
		}

		.info-container {
			gap: 15px;

			.avatar {
				width: 25px;
				height: 25px;
				background-color: transparent;
				border-radius: 50%;
				opacity: 1;
				transition: 0.3s all;

				&.small .avatar__image {
					width: 25px;
					height: 25px;
				}
			}

			.notif-container {
				width: 25px;
				transition: 0.3s all;
			}
		}

		&.active {
			border-bottom: 1px solid transparent;

			.mobile-header-logo {
				padding: 0;
				background: $color-app-main;

				a.nav-link {
					display: flex;
					width: 130px;
					visibility: visible;

					&.active {
						padding: 0 15px;
						background-color: $color-blue-ghost;
						border-bottom: 1px solid $color-blue-focus;
					}
				}
			}

			.info-container {
				width: 0;
				overflow: hidden;
			}
		}
	}
}
