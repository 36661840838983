@use '../../../../../assets/styles/colors' as *;
@use '../../../../../assets/styles/variables' as *;
@use '../../../../../assets/styles/mixins' as *;

.nav-item {
	display: flex;
	align-items: center;
	padding: 13px 16px;
	color: $color-text-black;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.04em;
	border-radius: 8px;
	transition: $transition-main;

	span {
		font-weight: 500;
	}

	& > .icon {
		width: 24px;
		min-width: 24px;
		height: 24px;
		margin-right: 12px;

		& > i {
			display: block;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	&.notif-active {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 20px;
			right: 25px;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: $color-purple;
		}
	}

	&--color-1 {
		&:hover,
		&.nav-item--active {
			background-color: #30323b1a;
		}
	}

	&--color-2 {
		&:hover,
		&.nav-item--active {
			background-color: #27ceca1a;
		}
	}

	&--color-3 {
		&:hover,
		&.nav-item--active {
			background-color: $color-red-light-bg;
		}
	}

	&--color-4 {
		&:hover,
		&.nav-item--active {
			background-color: $color-purple-light-bg;
		}
	}

	&--color-5 {
		&:hover,
		&.nav-item--active {
			background-color: $color-blue-light-bg;
		}
	}

	&--color-6 {
		&:hover,
		&.nav-item--active {
			background-color: $color-yellow-ghost;
		}
	}

	&--color-7 {
		&:hover,
		&.nav-item--active {
			background-color: $color-red-light-bg-2;
		}
	}

	&.x-work {
		&.nav-item--color-2 {
			&:hover,
			&.nav-item--active {
				background-color: $color-green-ghost;
			}
		}

		&.nav-item--color-3 {
			&:hover,
			&.nav-item--active {
				background-color: $color-orange-ghost;
			}
		}

		&.nav-item--color-6 {
			&:hover,
			&.nav-item--active {
				background-color: $color-red-light-bg-2;
			}
		}
	}

	&.x-work-recruter {
		&.nav-item--color-4 {
			&:hover,
			&.nav-item--active {
				background-color: $color-blue-light-bg;
			}

			&.notif-active::before {
				background-color: $color-blue;
			}
		}
	}

	&.x-work-partners {
		&.nav-item--color-1 {
			&:hover,
			&.nav-item--active {
				background-color: $color-red-light-bg-2;
			}
		}
	}

	@include max-width(1400px) {
		margin-top: 0;
		margin-bottom: 12px;
		padding: 14px 18px;
		border-radius: 0;

		& > .icon {
			margin-right: 0;
		}

		span {
			display: none;
		}

		&--color-1 {
			&.nav-item--active {
				border-right: 2px solid #30323b;
			}
		}

		&--color-2 {
			&.nav-item--active {
				border-right: 2px solid #27ceca;
			}
		}

		&--color-3 {
			&.nav-item--active {
				border-right: 2px solid #eb5656;
			}
		}

		&--color-4 {
			&.nav-item--active {
				border-right: 2px solid #df00ca;
			}
		}

		&--color-5 {
			&.nav-item--active {
				border-right: 2px solid #5e84e4;
			}
		}

		&--color-6 {
			&.nav-item--active {
				border-right: 2px solid $color-yellow;
			}
		}

		&--color-7 {
			&.nav-item--active {
				border-right: 2px solid #e82c2c;
			}
		}

		&.x-work {
			&.nav-item--color-6 {
				&:hover,
				&.nav-item--active {
					border-right: 2px solid #e82c2c;
				}
			}
		}

		&.x-work-recruter {
			&.nav-item--color-4 {
				&.nav-item--active {
					border-right: 2px solid #5e84e4;
				}
			}
		}

		&.x-work-partners {
			&.nav-item--color-1 {
				&.nav-item--active {
					border-right: 2px solid #e82c2c;
				}
			}
		}
	}

	@include max-width(768px) {
		& > .icon {
			margin-right: 12px;
		}
	}
}
