@use '../../assets/styles/mixins' as *;
@use '../../assets/styles/animations' as *;

.preloader-component {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100px;
	overflow: hidden;

	&.full {
		height: 100%;
	}

	& > .preloader {
		width: 28px;
		height: 28px;

		@include bg-image('img/preloader.png', auto);
		@include spin;
	}
}
