@use '../../../assets/styles/colors' as *;
@use '../../../assets/styles/variables' as *;
@use '../../../assets/styles/mixins' as *;

.input-main-container {
	position: relative;
	width: 100%;

	label {
		position: absolute;
		top: 14px;
		left: 17px;
		color: $color-input-placeholder;
		transition: $transition-main;
	}

	input {
		width: 100%;
		height: 48px;
		padding: 13px 16px;
		font-size: 16px;
		background-color: $color-app-main;
		border: 1px solid $color-border;
		outline: none;
		transition: $transition-main;

		&::placeholder {
			color: $color-input-placeholder;
		}

		&:focus,
		&:not([value='']) {
			padding-top: 20px;
			padding-bottom: 7px;

			& + label {
				top: 5px;
				font-size: 12px;
			}
		}

		&:focus {
			border-color: $color-blue-focus;
		}
	}

	&.error {
		input {
			border-color: $color-red;
		}
	}
	@include max-width(1200px) {
		&.user-name {
			order: 1;
		}
		&.user-surname {
			order: 2;
		}
		&.user-father-name {
			order: 3;
		}
		&.user-email {
			order: 7;
		}
	}

	@include max-width(768px) {
		max-width: 100%;
	}
}
