@use '../../../../../../../assets/styles/_colors.scss' as *;

.main {
	&__general-terms {
		grid-column: 2/3;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.general-terms {
			&__body {
				transform: rotate(-29deg);
			}

			&__term {
				font-weight: 700;
				font-size: 74px;
				text-transform: uppercase;
				color: $color-landing-gray-text;
				line-height: 80%;
				letter-spacing: 0px;

				&--rigth-skew {
					transform: skewX(-30deg);
				}

				&--left-skew {
					transform: skewX(30deg);
				}
			}
		}
	}

	@media (max-width: 1280px) {
		&__general-terms {
			.general-terms__term {
				font-weight: 800;
				font-size: 60px;
			}
		}
	}

	@media (max-width: 992px) {
		&__general-terms {
			margin-top: -100px;
			.general-terms__term {
				font-size: 50px;
			}
		}
	}

	@media (max-width: 768px) {
		&__general-terms {
			.general-terms__term {
				font-size: 44px;
			}
		}
	}

	@media (max-width: 576px) {
		&__general-terms {
			margin-top: 0;
			min-height: 420px;

			.general-terms__term {
				font-size: 35px;
			}
		}
	}

	@media (max-width: 400px) {
		&__general-terms {
			margin-left: 20px;
			min-height: 350px;
		}
	}
}
