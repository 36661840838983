@use '../../../../../../assets/styles/_colors.scss' as *;
@use '../../../../../../assets/styles/_variables.scss' as *;

.burger-menu {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: -100vh;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: $color-landing-white;
	transition: $transition;

	&.show {
		top: 0;
	}

	.nav-links {
		margin-bottom: 35px;
	}

	.button {
		width: 205px;
		margin-bottom: 40px;
		padding: 15px 20px;
	}
}
